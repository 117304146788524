import React from 'react'
import Fade from 'react-reveal/Fade'

const Works = () => {
    return (
        <div>
            <div className="row text-center mb-5">
                <div className="col-md-12 align-self-center">
                    <Fade bottom><p>a certain <span className="text-gold">blockchain project</span></p></Fade>
                    <Fade bottom><h2>Create something with <span className="text-gold-2">"Prestruct"</span> network</h2></Fade>
                </div>
                <div className="col-md-8 mx-auto text-center">
                    <div className="row">
                        <div className="col-md-3 mx-auto">
                            <Fade bottom>
                                <img src="/Construct.svg"  className="img-fluid"/>
                            </Fade>
                        </div>
                    </div>
                    <Fade bottom>
                        <p>This project started with the manisfestation of passion towards <span className="text-gold">Web 3.0</span> and <span className="text-gold">decentralization</span>. The blockchain runs on proof-of-stake network and made with geth. (Currently just a web design now because blockchain platform is down)</p>
                    </Fade>
                </div>
                <div className="col-md-6 mx-auto">
                    <Fade bottom>
                        <img src="/prestruct-site.png" className="img-card"/>
                    </Fade>
                </div>
                <div className="col-md-12 mt-4 mb-5">
                    <Fade bottom><a href="https://pollyreal1.github.io/prestruct/" target="_blank" className="btn btn-warning mb-5">Visit Prestruct</a></Fade>
                </div>
            </div>

            <div className="row text-center mt-5 mb-5">
                <div className="col-md-12 align-self-center">
                    <Fade bottom><p>a certain <span className="text-gold">blockchain game</span></p></Fade>
                    <Fade bottom><h2>Mint and Stake your NFT's with <span className="text-gold-2">"Pixels Tycoon"</span></h2></Fade>
                </div>
                <div className="col-md-8 mx-auto align-self-center text-center">
                    <Fade bottom><img src="/pixil-frame-0.png"  className="img-fluid"/></Fade>
                    <Fade bottom>
                        <p>With sudden rise of NFT's and cryptocurrency this project was made. With simple mechanics of <span className="text-gold">minting NFT's</span> and <span className="text-gold">Staking</span> it on the <span className="text-gold">Binance Smart Chain</span>. The players can gain rewards by unstaking their NFT's.</p>
                    </Fade>
                </div>
                <div className="col-md-6 mx-auto">
                    <Fade bottom>
                        <img src="/pixelstycoon-game.png" className="img-card"/>
                        <small>Want to try the game? Get BNB through <a href="https://testnet.binance.org/faucet-smart" target="_blank" className="text-gold">BSC Testnet faucet</a></small>
                    </Fade>
                </div>
                <div className="col-md-12 mt-4 mb-5">
                    <Fade bottom><a href="http://pixelstycoon.com" target="_blank" className="btn btn-warning mb-5">Visit Pixels Tycoon</a></Fade>
                </div>
            </div>

            <div className="row text-center mt-5 mb-5">
                <div className="col-md-12 align-self-center">
                    <Fade bottom><p>a certain <span className="text-gold">woocommerce plugin</span></p></Fade>
                    <Fade bottom><h2>Integrate your products with LEX using<span className="text-gold-2">"LEX Woocommerce Plugin"</span></h2></Fade>
                </div>
                <div className="col-md-8 mx-auto text-right align-self-center">
                    <div className="row">
                        <div className="col-md-4 mx-auto">
                            <Fade bottom><img src="/lex-woocommerce-plugin.png"  className="img-fluid"/></Fade>
                        </div>
                    </div>
                    <Fade bottom>
                        <p>With products needed <span className="text-gold">integration</span> to other clients online's stores and for <span className="text-gold">automation</span> of creation of contracts from the clients this plugin is made.</p>
                    </Fade>
                </div>
                <div className="col-md-12 mt-2 mb-5">
                    <Fade bottom> <a href="https://paulreal.gitbook.io/lex-woocommerce-plugin/" target="_blank" className="btn btn-warning mb-4">Visit Documentation</a></Fade>
                </div>
            </div>
        </div>
    )
}

export default Works
