import './App.css';
import { useState } from 'react';
import Nav from './components/Nav';
import Fade from 'react-reveal/Fade';
import ReactTypingEffect from 'react-typing-effect';
import Works from './components/Works';
import Contact from './components/Contact';

function App() {

    const [footerMsg, setFooterMsg] = useState(true) ;

    return (
        <div className="App">
            <Nav></Nav>

            
            <div id="about" class="header">
                
                <div class="container">
                    {/* <div className="row">
                        <div className="col-4 col-md-2 mx-auto">
                            <img src="/me1.jpg" class="img-fluid"/>
                        </div>
                    </div> */}
                    <br/>
                        <Fade>
                            <small>i am <span className="text-gold">paul real</span></small>
                            <h1 className="text-gold-2">Full Stack Web Developer</h1>
                        </Fade>
                        <Fade>
                            <p>Hi y'all i'm a web developer based in PH. Graduated with BSIT specialzing in web and mobile applications. Now making rounds in the digital space by making web systems using PHP and other frameworks(see below). This dev right here also develop his own websites and use tools such as git, jira, and other tools. And mostly deploy my own websites/systems using AWS(For automation of course!).</p>
                        </Fade>
                </div>
            </div>


            <div class="content-div">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <Fade left>
                                <img src="/Paulreal.png" className="img-fluid"></img>
                            </Fade>
                        </div>
                        <div class="col-md-9 align-self-center text-center">
                            <Fade right>
                                <h1>Added some effects for the <span className="text-gold">visual</span>!</h1>
                                <p>Logo made by <span className="text-gold-2">yours truly</span></p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-div">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center">
                            <Fade bottom>
                                <h1 className="text-gold">Main Stack</h1>
                                <p>Vue | React / <span className="text-gold-2">PHP</span> / MySQL / AWS</p>
                            </Fade>
                        </div>
                        <div class="col-md-6">
                            <Fade bottom>
                            <div className="card card-body bg-dark text-center">
                                <pre className="mt-3">
                                    <ReactTypingEffect
                                        text={["<?php echo 'Hello world'; ?>"]}
                                        cursorRenderer={cursor => <>{cursor}</>}
                                        displayTextRenderer={(text, i) => {
                                        return (
                                            <>
                                            {text.split('').map((char, i) => {
                                                const key = `${i}`;
                                                return (
                                                <span
                                                    key={key}
                                                    className="text-gold-3"
                                                >{char}</span>
                                                );
                                            })}
                                            </>
                                        );
                                        }}        
                                    />
                                </pre>
                            </div>
                            </Fade>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-6">
                            <Fade bottom>
                            <div className="card card-body bg-dark">
                                <pre className="mt-3">
                                    <code>
                                    {
                                        'pragma solidity ^0.8.0\n\nimport "@openzeppelin/contracts/token/ERC20/ERC20.sol";\n\ncontract PAULToken is ERC20 {\n\tconstructor(uint256 initialSupply) '}
                                        <ReactTypingEffect
                                            text={['ERC20("PAUL", "PLT") ']}
                                            cursorRenderer={cursor => <>{cursor}</>}
                                            displayTextRenderer={(text, i) => {
                                            return (
                                                <>
                                                {text.split('').map((char, i) => {
                                                    const key = `${i}`;
                                                    return (
                                                    <span
                                                        key={key}
                                                        className="text-gold-3"
                                                    >{char}</span>
                                                    );
                                                })}
                                                </>
                                            );
                                            }}        
                                        />
                                        {' {\n\t\t_mint(msg.sender, initialSupply);\n\t}\n}'
                                    }
                                    </code>
                                </pre>
                            </div>
                            </Fade>
                        </div>
                        <div class="col-md-6 align-self-center text-center mt-4">
                            <Fade bottom>
                                <h2 className="text-gold">Substack</h2>
                                <p>React / <span className="text-gold-2">Solidity</span> / Truffle / Ganache</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <div id="works" class="content-div">
                    <div class="container">
                        <Works/>
                    </div>
            </div>

            <div id="contact" class="content-div-light text-center mb-5">
                <Contact/>
            </div>

            { footerMsg ? (
                <div class="footer bg-dark fixed-bottom text-center d-flex">
                    <span class="ms-auto">Prestruct and pixelstycoon is currently down right now. The developer need funds.</span>
                    <div class="ms-auto close-btn" onClick={() => setFooterMsg(!footerMsg)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            ) : null }

                <div class="footer text-center mb-2">
                    <p>Paul Real @ 2022</p>
                </div>
        
        </div>
    );
}

export default App;
