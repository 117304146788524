import React, { useState } from 'react'

const Contact = () => {

    const [formState, setFormState] = useState({
        email: "",
        fullname: "",
        subject: "",
        message: "",
    })

    const handleChange = (e) => {
        const value = e.target.value;
        setFormState({
            ...formState,
            [e.target.name]: value
        })
    }

    const submitMail = async () => {
        let sendReq = await fetch('http://localhost:5000/send-mail', {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({ formState })
        })
            .then((res) => res.json)
            .then(() => {
                setFormState({
                    email: "",
                    fullname: "",
                    subject: "",
                    message: "",
                });
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div>
            <div class="container">
                <h1>Don't just <span class="text-gold-2">HODL</span> your thoughts, lets <span class="text-gold-2">BUIDL</span> your <span class="text-gold-2">project</span>!</h1>
                <div class="row">
                    <div class="col-md-8 mx-auto">
                        <form class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="inputEmail">Email address</label>
                                    <input type="email" class="form-control bg-dark" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter your email" name="email" value={formState.email} onChange={handleChange} />
                                    <small id="emailHelp" class="form-text text-muted">I'll never share your information with anyone else.</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="inputFullName">Full Name</label>
                                    <input type="text" class="form-control bg-dark" id="inputFullName" aria-describedby="emailHelp" placeholder="Enter your full name" name="fullname" value={formState.fullname} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="inputFullName">Subject</label>
                                    <input type="text" class="form-control bg-dark" id="inputFullName" aria-describedby="emailHelp" placeholder="Your subject" name="subject" value={formState.subject} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="form-group">
                                    <label for="inputFullName">Message</label>
                                    <input type="text" class="form-control bg-dark" id="inputFullName" aria-describedby="emailHelp" placeholder="Your message" name="message" value={formState.message} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="button" class="btn btn-warning mt-4" onClick={submitMail}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
